
const PriceComp = () => {

    const packages = [
        {
            name: "Paket Panda 🐼",
            features: [
                "Hakuna Matata pozivnice",
                "2h zabave",
                "do 15 djece",
                "Odgojitelj + animator",
                "Grickalice, keksi i negazirani sokovi",
                "Posebni poklon za slavljenika",
                "Osnovni poklon za svakog pozvanog gosta",
                "Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)"
            ],
            price: "175,00€ (kotizacija 50,00€)"
        },
        {
            name: "Paket Slon 🐘",
            features: [
                "Hakuna Matata pozivnice",
                "2h zabave",
                "do 25 djece",
                "Odgojitelj + animator",
                "Grickalice, keksi i negazirani sokovi",
                "Posebni poklon za slavljenika",
                "Osnovni poklon za svakog pozvanog gosta",
                "Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)"
            ],
            price: "195,00€ (kotizacija 60,00€)"
        },
        {
            name: "Paket Žirafa 🦒",
            features: [
                "Hakuna Matata pozivnice",
                "3h zabave",
                "do 15 djece",
                "Odgojitelj + animator",
                "Grickalice, keksi i negazirani sokovi",
                "Posebni poklon za slavljenika",
                "Osnovni poklon za svakog pozvanog gosta",
                "Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)"
            ],
            price: "195,00€ (kotizacija 60,00€)"
        },
        {
            name: "Paket Lav 🦁",
            features: [
                "Hakuna Matata pozivnice",
                "3h zabave",
                "do 25 djece",
                "Odgojitelj + animator",
                "Grickalice, keksi i negazirani sokovi",
                "Posebni poklon za slavljenika",
                "Osnovni poklon za svakog pozvanog gosta",
                "Mogućnost dodatka pizza i/ili rođendanske torte (25€, 20€)"
            ],
            price: "215,00€ (kotizacija 70,00€)"
        },
        {
            name: "Paket Movie Night 🎥",
            features: [
                "Gledanje filma po dogovoru",
                "2h zabave",
                "do 18 djece",
                "Odgojitelj + animator",
                "Kokice, gazirani i negazirani sokovi",
                "Posebni poklon za slavljenika",
                "Osnovni poklon za svakog pozvanog gosta"
            ],
            price: "170,00€ (kotizacija 60,00€)"
        }
    ];



    return (
        <div id="main">
            {/* One section */}
            <section id="price" className="tiles">
                {packages.map((pkg, index) => (
                    <article className="rest" key={index}>
                        <span className="image"></span>
                        <header className="major">
                            <h3><a href="#contact" className="link">{pkg.name}</a></h3>
                            <ul className="custom-list">
                                {pkg.features.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                ))}
                            </ul>
                            <h4>{pkg.price}</h4>
                        </header>
                    </article>
                ))}
                <article className="rest">
                    <span className="image"></span>
                    <header className="major">
                        <h3><a href="#contact" className="link" aria-label="Kontakt za dodatne opcije">Dodatne opcije &#129417;</a></h3>
                        <ul className="custom-list">
                            <li>Mogućnost zajedničke proslave za braću i sestre</li>
                            <li>Zajednička proslava za dvoje prijatelja iz razreda ili vrtića.</li>
                        </ul>
                    </header>
                </article>
            </section>
        </div>
    );
};


export default PriceComp;